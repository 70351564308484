import React from "react";
import Pagination from "../pagination.js";

const PostPagination = (props) => {
  const domNode = props.domNode;
  const index = (() => {
    if (!domNode.next) {
      return 1000;
    }
    if (!domNode.prev) {
      return 0;
    }
    return domNode.prev.children.length+1;
  })();
  const options = JSON.parse(domNode.children[0].data);
  return (<Pagination index={ index } {...props.pagination } options={options} />);
};

export default PostPagination;
